<div class="container">
    <h1>RECAMBIOS Y REPUESTOS</h1>

   
   <div class="azul">
       <ul>
           <li  class="azul">Junta culata</li>
           <li  class="azul">Tornillos</li>
           <li  class="azul">Juntas parte alta</li>
           <li  class="azul">Juego descarbonización completo</li>
           <li  class="azul">Cojinetes</li>
           <li  class="azul">Segmentos</li>
           <li  class="azul">Pistones</li>
           <li  class="azul">Bielas</li>
           <li  class="azul">Arboles de levas</li>
           <li  class="azul">Kit de distribución</li>
           <li  class="azul">...</li>
       </ul>
   </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private numero:number=5;
  constructor() {
    
   }

  ngOnInit(): void {

    console.log("numero:"+this.numero);
  }

}

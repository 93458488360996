import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helicoils',
  templateUrl: './helicoils.component.html',
  styleUrls: ['./helicoils.component.css']
})
export class HelicoilsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

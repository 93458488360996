
<p>Rectificado de motores</p>
<p>Rectificado de cigueñales</p>
<p>Rectificado de asientos</p>
<p>Extraccion de calentadores (*)</p>
<p>Extracción de inyectores (*)</p>
<p>Montaje de helicoils (*)</p>
<p>Torno y fresa</p>
<p>Recambios y accesorios</p>
<p>Mandrinado de bancada</p>
<p>Mandrinado de biela</p>
<p>Soldadura</p>

<h1>TALLER E INSTALACIONES PROPIAS</h1>

<p>(*) Servicio a domicilio en Comunidad de Madrid. Resto Consultar </p>

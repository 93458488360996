<footer class="container-fluid">
  <div><iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.6801217302177!2d-3.626978084685316!3d40.43808147936269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422f0b06558935%3A0x1cb8fc33aba3002!2sCalle%20de%20Albasanz%2C%2062%2C%2028037%20Madrid!5e0!3m2!1ses!2ses!4v1593599614297!5m2!1ses!2ses"
      width="400" height="250" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
      tabindex="0"></iframe>
  </div>
  <div>
    <H1>PROGESRALE S.L.<br>
      C/ ALBASANZ 62<br>
      28037 MADRID</H1>
  </div>
  <div><h1><mat-icon>phone</mat-icon> 913770677  <img src="assets/imagenes/whatsapp.png"> 609208499</h1>
    
    <h1><mat-icon>mail</mat-icon><a href="mailto:recrale@recrale.com">recrale@recrale.com</a></h1> </div>
</footer>
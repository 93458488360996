import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recambios',
  templateUrl: './recambios.component.html',
  styleUrls: ['./recambios.component.css']
})
export class RecambiosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

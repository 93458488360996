<div class="container">
    <h1>PLANIFICADO DE CULATAS</h1>

   
    <div style="text-align: center;"> <img src="assets/imagenes/PLANIF_CULATA.gif" width="480" height="360"><img
            src="assets/imagenes/asiento.GIF" width="480" height="360">

            <img src="assets/imagenes/antesdespues.jpg" width="480" height="360"><img
            src="assets/imagenes/anetsdespues1.jpg" width="480" height="360">
            <img
            src="assets/imagenes/culata.gif" width="480" height="360">
            
    </div>
</div>
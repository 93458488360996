<div class="container" style="margin-top: 20px;">
    <div id="rale" class="carousel slide" data-ride="carousel" style="text-align: center;">
      <ul class="carousel-indicators">
        <li data-target="#rale" data-slide-to="0" class="active"></li>
        <li data-target="#rale" data-slide-to="1"></li>
        <li data-target="#rale" data-slide-to="2"></li>
        <li data-target="#rale" data-slide-to="3"></li>
        <li data-target="#rale" data-slide-to="4"></li>
        <li data-target="#rale" data-slide-to="5"></li>  
             
      </ul>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="assets/imagenes/torno.png" alt="Torno" width="600" height="300" class="rounded">
          
        </div>
        
        <div class="carousel-item">
          <img src="assets/imagenes/motor.png" alt="Rectificadora" width="600" height="300" class="rounded">
          
        </div>
       

        <div class="carousel-item">
          <img src="assets/imagenes/ciguenal.png" alt="Cigueñal" width="600" height="300" class="rounded">
          
        </div>
        <div class="carousel-item">
          <img src="assets/imagenes/bancada.png" alt="Bancada" width="600" height="300" class="rounded">
          
        </div>
        <div class="carousel-item">
          <img src="assets/imagenes/planificadora1.png" alt="Planificado" width="600" height="300" class="rounded">
          
        </div>
        <div class="carousel-item">
          <img src="assets/imagenes/planificadora2.png" alt="Planificado" width="600" height="300" class="rounded">
          
        </div>
      
     
      </div>
      
      <a class="carousel-control-prev" href="#rale" data-slide="prev">
        <span style="color: black;"><mat-icon>skip_previous</mat-icon></span>
      </a>
      <a class="carousel-control-next" href="#rale" data-slide="next">
        <span style="color: black;"><mat-icon>skip_next</mat-icon></span>
      </a>
    </div>
    <div class="jumbotron naranja" style="text-align: center;">
      <h1 >Extracción de calentadores</h1> 
      <div><img src="assets/imagenes/calentador1.png" width="300" height="300" class="rounded-circle">&nbsp;&nbsp;<img src="assets/imagenes/calentador2.png" width="300" height="300" class="rounded-circle">&nbsp;&nbsp;<img src="assets/imagenes/calentador3.png" width="300" height="300" class="rounded-circle"></div>

      <h1 >Extracción de inyectores</h1> 
      <div><img src="assets/imagenes/inyector1.png" width="300" height="300" class="rounded-circle">&nbsp;&nbsp;<img src="assets/imagenes/inyector2.png" width="300" height="300" class="rounded-circle">&nbsp;&nbsp;<img src="assets/imagenes/inyector3.png" width="300" height="300" class="rounded-circle"></div>

      <h1 >Inserción de helicoils</h1> 
      <div><img src="assets/imagenes/helicoils1.png" width="300" height="300" class="rounded-circle">&nbsp;&nbsp;<img src="assets/imagenes/helicoils.png" width="300" height="300" class="rounded-circle">&nbsp;&nbsp;<img src="assets/imagenes/helicoil2.png" width="300" height="300" class="rounded-circle"></div>

      <h1 style="margin-top: 20px;">¡¡¡ A DOMICILIO EN LA COMUNIDAD DE MADRID, RESTO CONSULTAR !!!<br> Pídenos cita </h1> 
      <H1><mat-icon>phone</mat-icon> 913770677  <img src="assets/imagenes/whatsapp.png"  class="rounded"> 609208499 <mat-icon>mail</mat-icon> <a href="mailto:recrale@recrale.com">recrale@recrale.com</a></H1>
    </div>
  </div>